import { Icon } from '../../../symbols';
import style from './item.module.css';
import { ComponentProps, forwardRef, PropsWithChildren } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { Text } from '../../../typography';
import { Profile, ProfileType } from '../../types';

type ProfileItemProps = {
  profile: Profile;
  current?: boolean;
};

type ItemProps = PropsWithChildren<{
  icon?: ComponentProps<typeof Icon>['name'];
  asChild?: boolean;
}>;

export const ProfileNavItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ icon, children, asChild }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp className={style.item} ref={ref}>
        {icon && <Icon name={icon} className={style.icon} />}
        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);

export function ProfileSelectItem({ profile, current }: ProfileItemProps) {
  return (
    <ProfileNavItem icon={getIcon(profile.profileType)} asChild>
      <button name="profile" value={profile.id} className={style.element}>
        <div>
          <Text variant="body-small" asChild>
            <div>{profile.profileType}</div>
          </Text>
          <Text variant={current ? 'body-bold' : 'body'} asChild>
            <div>{profile.name}</div>
          </Text>
        </div>
        {current && <Icon name="checkmark" className={style.checkmark} />}
      </button>
    </ProfileNavItem>
  );
}

function getIcon(profileType: ProfileType) {
  switch (profileType) {
    case ProfileType.Personal:
      return 'user';
    case ProfileType.Company:
      return 'farm';
  }
}
