import { SidebarLayout, Teaser, Text } from '@lhb/ui';
import { Form, formAction } from '../helpers/form';
import { useTranslation } from 'react-i18next';
import { Field } from '@lhb/form';
import { IsEmail, IsMobile, isString } from '@lhb/validation';
import {
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useParams,
} from 'react-router-dom';
import {
  BadRequestException,
  excludeNull,
  HttpStatus,
  UnauthorizedException,
} from '@lhb/utils';
import { isApiError } from '@lhb/api';
import {
  ContactInformationService,
  GetContactInformationResponse,
  OpenAPI,
  UpdateContactInformationResponse,
} from '@lhb/api/idp';
import { ReturnUrlStore } from '../helpers/return-url.store';
import { useEffect } from 'react';

class ContactDto {
  @Field({ type: 'tel', label: 'MOBILE_FIELD_LABEL', required: true })
  @IsMobile({
    message: ({ value }) => {
      if (!isString(value)) return 'INVALID_MOBILE';
      value = value.replace(/-/g, '');
      if (/^\D+$/.test(value)) return 'INVALID_MOBILE';
      if (value.length < 10) return 'TOO_SHORT_MOBILE';
      if (value.length > 10) return 'TOO_LONG_MOBILE';
      return 'INVALID_MOBILE';
    },
  })
  mobile!: string;

  @Field({
    type: 'email',
    label: 'EMAIL_FIELD_LABEL',
    required: true,
  })
  @IsEmail(undefined, { message: 'INVALID_EMAIL' })
  email!: string;
}

type ContactInfoLoaderResponse = GetContactInformationResponse & {
  requestId: string;
};

export async function contactInfoLoader({ params }: LoaderFunctionArgs) {
  const { requestId } = params;
  if (!requestId) throw new BadRequestException('MISSING_REQUEST_ID');
  try {
    const oldData = await ContactInformationService.getContactInformation({
      requestId,
    });
    return excludeNull(oldData);
  } catch (error) {
    if (!isApiError(error)) throw error;
    if (error.status === HttpStatus.BAD_REQUEST)
      throw new BadRequestException('INVALID_SESSION');
    return {};
  }
}

export function ContactInfoPage() {
  const { lastUpdatedDate, ...initialValues } =
    useLoaderData() as ContactInfoLoaderResponse;
  const actionData = useActionData() as { success: boolean };
  const { requestId } = useParams();
  const { t } = useTranslation('CONTACT_INFO');

  useEffect(() => {
    if (!actionData?.success) return;
    const returnUrl = ReturnUrlStore.get();
    const searchParams = new URLSearchParams();
    if (returnUrl) searchParams.append('returnUrl', returnUrl);
    searchParams.append('verified', 'true');
    window.location.href = `${OpenAPI.BASE}/BankIdAuthorize/validate/${requestId}?${searchParams.toString()}`;
  }, [actionData]);

  if (!requestId) throw new BadRequestException('MISSING_REQUEST_ID');

  const sidebar = (
    <Teaser
      title={t('PRIVACY_INFO_TITLE')}
      pictogram="signature"
      ctaLabel={t('READ_MORE', { ns: 'COMMON' })}
      external
      to="https://www.landshypotek.se/personuppgiftshantering"
    >
      {t('PRIVACY_INFO_BODY')}
    </Teaser>
  );

  return (
    <>
      <SidebarLayout sidebar={sidebar}>
        <Text asChild variant="title">
          <h1>{t('CONTACT_INFO_HEADER')}</h1>
        </Text>
        <Text variant="lead">
          {t('CONTACT_INFO_LEAD', {
            lastUpdatedDate,
          })}
        </Text>
        <Form
          schema={ContactDto}
          values={initialValues}
          invert
          i18nNs="CONTACT_INFO"
          submitLabel={t('CONTACT_INFO_SUBMIT')}
        />
      </SidebarLayout>
    </>
  );
}

export const contactInfoAction = formAction<
  typeof ContactDto,
  UpdateContactInformationResponse
>({
  schema: ContactDto,
  mutation: async (requestBody, { params: { requestId } }) => {
    if (!requestId) throw new BadRequestException('MISSING_REQUEST_ID');
    try {
      requestBody.mobile = requestBody.mobile.replace(/-/g, '');
      await ContactInformationService.updateContactInformation({
        requestId,
        requestBody,
      });
      return { success: true };
    } catch (error) {
      return { success: false, errors: error };
    }
  },
});
