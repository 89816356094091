import { forwardRef, PropsWithChildren } from 'react';
import style from './button.module.css';
import { Icon } from '../../../symbols';

type ProfileSelectorProps = PropsWithChildren<{
  id: string;
  type?: 'physical' | 'judicial';
}>;

export const ProfileButton = forwardRef<
  HTMLButtonElement,
  ProfileSelectorProps
>(({ id, children, type = 'physical' }, ref) => {
  return (
    <>
      <button
        popovertarget={id}
        ref={ref}
        className={style.element}
        style={{
          /* @ts-expect-error: types doens't account for custom props */
          anchorName: `--profile-selector-button-${id}`,
        }}
      >
        <Icon name={type === 'physical' ? 'user' : 'farm'} />
        {children}
        <Icon name="chevron-down" className={style.chevron} />
      </button>
    </>
  );
});
